
import {
  Component, Vue, Prop,
} from 'vue-property-decorator';
import { getComponent } from '@/utils/helpers';
import draggable from 'vuedraggable';
import Multiselect from 'vue-multiselect';
import metaDataModule from '@/store/modules/metaDataModule';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
    Multiselect,
    TasqsDetails: () => getComponent('tasqs/TasqsDetails'),
    Dropdown: () => getComponent('common/Dropdown'),
    TasqsMobile: () => getComponent('tasqs/TasqDetailMobile'),
    DateRangePicker,
    draggable,
  },
})
export default class DataListDetailPopup extends Vue {
   @Prop({ type: Object, required: false }) datalist?: any;

  @Prop({ type: Array, required: false, default: [] }) datalistTitles: any;

   errors: any = []

   dataLoading = false;

   validateForm() {
     this.errors = [];

     if (this.datalist.title.length < 1) {
       this.errors.push('datalistTitle');
       return false;
     }
     const { title } = this.datalist;
     if (this.datalist.title && this.datalistTitles.includes(title.toLowerCase())) {
       this.errors.push('datalistTitleExist');
       return false;
     }
     if (this.datalist.options.length < 1) {
       this.errors.push('datalistOptions');
       return false;
     } if (this.datalist.options.length > 0 && !this.datalist.options.find((o) => o.isActive)) {
       this.errors.push('datalistActive');
       return false;
     }
     return true;
   }

   addDatalistNewItem() {
     this.errors = this.errors.filter((error) => error !== 'datalistNewItem');
     if (this.datalist.options.find((option) => option.title.toLowerCase() === this.datalist.newVal.toLowerCase())) {
       this.errors.push('datalistNewItem');
       return;
     }
     this.datalist.options.push({ title: this.datalist.newVal, isActive: this.datalist.isActive });
     this.datalist.options = this.datalist.options.sort((a, b) => a.title.localeCompare(b.title));
     this.$nextTick(() => {
       const element = document.getElementById(this.datalist.newVal)!;
       element.scrollIntoView({ behavior: 'auto', block: 'center', inline: 'nearest' });
       this.datalist.newVal = '';
     });
   }

   deleteFieldOption(option) {
     this.datalist.options = this.datalist.options.filter((item) => item.title !== option.title);
   }

   async saveDatalist() {
     //  await metaDataModule.deleteDatalist({ id: 1 });
     const isValid = this.validateForm();
     if (isValid) {
       this.dataLoading = true;
       if (this.datalist.title && this.datalist.options && this.datalist.options.length) {
         const listItems = this.datalist.options.map((list) => ({
           active: list.isActive ? true : false,
           title: list.title,
         }));

         if (this.datalist.id) {
           await metaDataModule.putDatalist({ id: this.datalist.id, title: this.datalist.title, listItems });
         } else {
           await metaDataModule.postDatalist({ title: this.datalist.title, options: listItems });
         }
       }

       this.$emit('save-popup');
       this.dataLoading = false;
     }
   }
}
